<template>
    <div>
        <div class="exa-head">
            <div class="title"><i class="el-icon-edit-outline" style="color: #1ea0fa;"></i> 绩效考核详情</div>
            <div class="back"><el-button style="color: #1ea0fa;" @click="$router.go(-1)">返回</el-button></div>
        </div>
        <div class="info-content">
            <div class="info-left">
                <div class="left-tit"><i></i>考核详情</div>

                <div class="plan">
                    <!-- <div>已提交：<span>{{leftData}}</span></div>
                    <div>未提交：<span>{{leftData}}</span></div> -->
                    <div>已评分：<span>{{ leftData.ComplateCount }}</span></div>
                    <div>未评分：<span>{{ leftData.AllCount - leftData.ComplateCount }}</span></div>
                    <div>当前进度：<span>{{ ((leftData.ComplateCount / leftData.AllCount) * 100).toFixed(0) + "%" }}</span>
                    </div>
                    <div>满分：<span>{{ mfScores }}</span>
                    </div>
                </div>
                <div class="plan">考核方式: <span style="font-size: 14px;margin-left: 10px;">
                        {{ rightData.AssessmentType == 1 ? '自评+考核评' : rightData.AssessmentType == 2 ? '仅自评' : '仅考核评' }}
                    </span>
                </div>
                <div style="margin-bottom: 10px;">
                    <el-select v-model="orderType" placeholder="请选择考核排序" size="small" @change="getTeacher">
                        <el-option label="默认" :value="0"></el-option>
                        <el-option v-if="rightData.AssessmentType != 3" label="自评分从高到低" :value="1"></el-option>
                        <el-option v-if="rightData.AssessmentType != 3" label="自评分从低到高" :value="2"></el-option>
                        <el-option v-if="rightData.AssessmentType != 2" label="考核分从高到低" :value="3"></el-option>
                        <el-option v-if="rightData.AssessmentType != 2" label="考核分从低到高" :value="4"></el-option>
                    </el-select>
                </div>
                <div class="teacher">
                    <div class="teacherTit">
                        <div class="">教师</div>
                        <div class="">状态</div>
                    </div>
                    <div class="teacherList">
                        <div class="item" v-for="(js, index) in leftData.teacherStates" :key="index"
                            :class="{ 'active': jsIndex == index }" @click="changeJs(index, js)">
                            <div class="name">{{ js.TeacherName }}</div>
                            <div class="" style="text-align: center; font-size: 12px;"
                                v-if="rightData.AssessmentType == 1 || rightData.AssessmentType == 2">
                                <div>自评分</div>
                                <div style="color:#0080ee; font-size: 14px;">{{ js.Self_All_Score }}</div>
                            </div>
                            <div class="" style="text-align: center; font-size: 12px;"
                                v-if="rightData.AssessmentType == 1 || rightData.AssessmentType == 3">
                                <div>考核评分</div>
                                <div style="color:#0080ee; font-size: 14px;">{{ js.Man_All_Score }}</div>
                            </div>
                            <div class="pf" :style="js.IsComplete ? 'color: #099aff' : 'color:#c6c6c6'">
                                {{ js.IsComplete ? '已评分' : '未评分' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="info-right">
                <div class="right-top">
                    <div class="name">当前教师：{{ rightData.Answer_User_Name }}</div>
                    <div class="right-mark">评分状态: <span>{{ rightData.IsComplete ? '已评分' : '未评分' }}</span></div>
                </div>

                <div class="right-tit">{{ rightData.Assessment_Name }}</div>

                <div class="time">
                    <span>开始时间：{{ rightData.Answer_Start_Time | datetime('YYYY-MM-DD') }}</span>
                    <span>结束时间： {{ rightData.Answer_End_Time | datetime('YYYY-MM-DD') }}</span>
                    <span>发布人：{{ rightData.Answer_User_Name }}</span>
                </div>

                <div class="prove">{{ rightData.Assessment_Description }}</div>

                <div class="">
                    <div class="scoreBox" v-for="(item, index) in rightData.TemplateParents" :key="index">
                        <div class="big">
                            <div class="">{{ item.Parent_Sort }}.{{ item.Parent_Title }}</div>
                            <div class="score">[标准分：{{ item.Parent_Score_Max }}分]</div>
                        </div>
                        <div class="scoreBig" v-if="item.Is_SetScore">
                            <div class="" v-if="rightData.AssessmentType == 1 || rightData.AssessmentType == 2">
                                <span>自评分：</span>
                                <!-- <el-input v-model="item.Self_Assessment_Score" type="number"
                                    :max="item.Parent_Score_Max" :min="0"
                                    @input="changeInputSelf(arguments[0], item.Parent_Score_Max, index)"
                                    :disabled="rightData.IsSelfComplate ? true : false"></el-input> -->
                                <el-input v-model="item.Self_Assessment_Score" type="number"
                                    :max="item.Parent_Score_Max" :min="0"
                                    @input="changeInputSelf(arguments[0], item.Parent_Score_Max, index)"
                                    :disabled="true"></el-input>
                            </div>
                            <div class="" v-if="rightData.AssessmentType == 1 || rightData.AssessmentType == 3">
                                <span>考核评分：</span>
                                <!-- <el-input v-model="item.ManUser_Score" type="number" :max="item.Parent_Score_Max"
                                    :min="0" @input="changeInput(arguments[0], item.Parent_Score_Max, index)"
                                    :disabled="rightData.AssessmentType == 1 && rightData.IsSelfComplate == 0 ? true : rightData.IsComplete ? true : false"></el-input> -->
                                <el-input v-model="item.ManUser_Score" type="number" :max="item.Parent_Score_Max"
                                    :min="0" @input="changeInput(arguments[0], item.Parent_Score_Max, index)"
                                    @change="refashMaxScore"
                                    :disabled="rightData.Man_UserIdList.indexOf(user.ID) < 0"></el-input>
                            </div>
                        </div>
                        <template v-if="item.Children.length">
                            <div class="child" v-for="(child, chInd) in item.Children" :key="chInd">
                                <div class="small">
                                    <div class="">{{ child.Child_Title }}</div>
                                    <div class="score">[标准分：{{ child.Child_Score_Max }}分]</div>
                                </div>
                                <div class="scoreSmall">
                                    <div class="" v-if="rightData.AssessmentType == 1 || rightData.AssessmentType == 2">
                                        <span>自评分：</span>
                                        <!-- <el-input v-model="child.Self_Assessment_Score" type="number"
                                            :max="child.Child_Score_Max" :min="0"
                                            @input="changeChildInputSelf(arguments[0], child.Child_Score_Max, index, chInd)"
                                            :disabled="rightData.IsSelfComplate ? true : false"></el-input> -->
                                        <el-input v-model="child.Self_Assessment_Score" type="number"
                                            :max="child.Child_Score_Max" :min="0"
                                            @input="changeChildInputSelf(arguments[0], child.Child_Score_Max, index, chInd)"
                                            :disabled="true"></el-input>
                                    </div>
                                    <div class="" v-if="rightData.AssessmentType == 1 || rightData.AssessmentType == 3">
                                        <span>考核评分：</span>
                                        <!-- <el-input v-model="child.ManUser_Score" type="number"
                                            :max="child.Child_Score_Max" :min="0"
                                            @input="changeChildInput(arguments[0], child.Child_Score_Max, index, chInd)"
                                            :disabled="rightData.AssessmentType == 1 && rightData.IsSelfComplate == 0 ? true : rightData.IsComplete ? true : false"></el-input> -->
                                        <el-input v-model="child.ManUser_Score" type="number"
                                            :max="child.Child_Score_Max" :min="0"
                                            @change="refashMaxScore"
                                            @input="changeChildInput(arguments[0], child.Child_Score_Max, index, chInd)"
                                            :disabled="rightData.Man_UserIdList.indexOf(user.ID) < 0"></el-input>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    
                    <div class="scoreBox" v-if="rightData.AssessmentType == 1 || rightData.AssessmentType == 3">
                        <div class="big">
                            <div class="">考核备注</div>
                            <!-- <div class="score">[标准分：{{ item.Parent_Score_Max }}分]</div> -->
                        </div>
                        <div class="scoreBig">
                            <el-input :disabled="rightData.Man_UserIdList.indexOf(user.ID) < 0" type="textarea" style="width:80%" v-model="rightData.Man_Remark" />
                        </div>
                    </div>
                    <div class="scoreBox">
                        <div class="big">
                            <div class="">总分</div>
                            <!-- <div class="score">[标准分：{{ item.Parent_Score_Max }}分]</div> -->
                        </div>
                        <div class="scoreBig">
                            <div class="" v-if="rightData.AssessmentType == 1 || rightData.AssessmentType == 2">
                                <span>自评总分：</span>
                                <label>{{allSelfDivide}}</label>
                            </div>
                            <div class="" v-if="rightData.AssessmentType == 1 || rightData.AssessmentType == 3">
                                <span>考核评总分：</span>
                                <label>{{allDivide}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="" v-if="false">教师未提交考核</div>
            </div>
        </div>
        <div class="bottom">
            <el-button @click="mfScore" v-if="rightData.Man_UserIdList&&rightData.Man_UserIdList.indexOf(user.ID) > -1">满分</el-button>
            <el-button @click="nextUp">上一步</el-button>
            <el-button @click="nextDown">下一步</el-button>
            <el-button v-if="rightData.Man_UserIdList&&rightData.Man_UserIdList.indexOf(user.ID) > -1" type="primary" @click="submit">完成</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            guid: this.$route.query.guid,
            leftData: {},
            jsIndex: 0,
            user: this.whale.user.get(),

            rightData: {},

            allDivide: 0,
            allSelfDivide: 0,

            orderType: 0,
            mfScores: 0
        }
    },
    mounted() {
        this.getTeacher()
    },
    methods: {
        getTeacher() {
            let self = this
            this.whale.remote.getResult({
                url: "/api/School/PFM/PfmRecord/GetAssessmentTeachers?guid=" + this.guid + '&orderType=' + this.orderType,
                completed: function (its) {
                    self.leftData = its.DATA
                    self.getInfo(self.leftData.teacherStates[self.jsIndex].TeacherId)
                }
            })
        },
        changeJs(i, val) {
            this.jsIndex = i
            this.getInfo(val.TeacherId)
        },

        getInfo(teacherId) {
            let self = this
            this.whale.remote.getResult({
                url: 'api/School/PFM/PfmRecord/GetAssessmentDetails?guid=' + this.guid + '&teacherId=' + teacherId,
                completed(its) {
                    console.log('fiabgia', its)
                    self.rightData = its.DATA


                    let mf=0 ;
                        self.rightData.TemplateParents.forEach((item) => {
                            mf+=item.Parent_Score_Max
                    });
                    self.mfScores=mf;
                },
            })
        },

        changeInput(val, e, i) {
            console.log(val, e, i)
            if (val > e) {
                this.rightData.TemplateParents[i].ManUser_Score = e
            }
        },
        changeInputSelf(val, e, i) {
            console.log(val, e, i)
            if (val > e) {
                this.rightData.TemplateParents[i].Self_Assessment_Score = e
            }
        },
        changeChildInput(val, e, p, i) {
            console.log(val, e, p, i)
            if (val > e) {
                this.rightData.TemplateParents[p].Children[i].ManUser_Score = e
            }
        },
        changeChildInputSelf(val, e, p, i) {
            console.log(val, e, p, i)
            if (val > e) {
                this.rightData.TemplateParents[p].Children[i].Self_Assessment_Score = e
            }
        },

        nextUp() {
            if (this.jsIndex == 0) {
                return
            } else {
                this.jsIndex--
                this.getInfo(this.leftData.teacherStates[this.jsIndex].TeacherId)
            }
        },
        nextDown() {
            if (this.jsIndex == this.leftData.teacherStates.length - 1) {
                return
            } else {
                this.jsIndex++
                this.getInfo(this.leftData.teacherStates[this.jsIndex].TeacherId)
            }
        },

        // 
        submit() {
            if (this.rightData.AssessmentType == 1) {
                if (this.rightData.IsSelfComplate == 0) {
                    // this.selfScore()
                    this.$message.warning('请先在手机端进行自评')
                } else {
                    this.AssessmentScore()
                }
            } else if (this.rightData.AssessmentType == 2) {
                // this.selfScore()
                // this.$message.warning('请在手机端')
            } else {
                this.AssessmentScore()
            }
        },
        // 自评
        selfScore() {
            let data = {
                IsComplete: 0,
                IsSelfComplate: 1,
                Assessment_Guid: this.rightData.Assessment_Guid,
                MainId: this.rightData.Record_Main_Id,
                assRecords: []
            }
            this.rightData.TemplateParents.map(item => {
                if (item.Children.length) {
                    item.Children.map(val => {
                        let obj = {
                            Assessment_Details_Id: val.ID,
                            Self_Assessment_Score: val.Self_Assessment_Score,
                            ManUser_Score: val.ManUser_Score
                        }
                        data.assRecords.push(obj)
                    })
                } else {
                    let obj = {
                        Assessment_Details_Id: item.ID,
                        Self_Assessment_Score: item.Self_Assessment_Score,
                        ManUser_Score: item.ManUser_Score
                    }
                    data.assRecords.push(obj)
                }
            })
            console.log('data', data)
            let self = this
            this.whale.remote.getResult({
                url: 'api/School/PFM/PfmRecord/CreateOrUpdateScore',
                data: data,
                completed() {
                    self.$message.success('提交成功')
                    self.getTeacher()
                }
            })
        },
        // 考核评
        AssessmentScore() {
            let data = {
                IsComplete: 1,
                IsSelfComplate: 1,
                Assessment_Guid: this.rightData.Assessment_Guid,
                MainId: this.rightData.Record_Main_Id,
                assRecords: [],
                Man_Remark:this.rightData.Man_Remark
            }
            this.rightData.TemplateParents.map(item => {
                if (item.Children.length) {
                    item.Children.map(val => {
                        let obj = {
                            Assessment_Details_Id: val.ID,
                            Self_Assessment_Score: val.Self_Assessment_Score,
                            ManUser_Score: val.ManUser_Score
                        }
                        data.assRecords.push(obj)
                    })
                } else {
                    let obj = {
                        Assessment_Details_Id: item.ID,
                        Self_Assessment_Score: item.Self_Assessment_Score,
                        ManUser_Score: item.ManUser_Score
                    }
                    data.assRecords.push(obj)
                }
            })
            console.log('data', data)
            let self = this
            this.whale.remote.getResult({
                url: 'api/School/PFM/PfmRecord/ManCreateOrUpdateScore',
                data: data,
                completed() {
                    self.$message.success('提交成功')
                    self.getTeacher()
                }
            })
        },
        mfScore(){
            let self = this
            self.rightData.TemplateParents.forEach((item) => {
                if (item.Is_SetScore) {
                    item.ManUser_Score=item.Parent_Score_Max;
                } else {
                    item.Children.forEach((val) => {
                        val.ManUser_Score=val.Child_Score_Max;
                    });
                }
            });
            this.refashMaxScore()

        },
        refashMaxScore(){
            var self=this;
            self.allDivide=0;
            self.allSelfDivide=0;
            self.rightData.TemplateParents.forEach((item) => {
                if (item.Is_SetScore) {
                    self.allDivide += Number(item.ManUser_Score);
                    self.allSelfDivide += Number(item.Self_Assessment_Score);
                } else {
                    item.Children.forEach((val) => {
                        self.allDivide += Number(val.ManUser_Score);
                        self.allSelfDivide += Number(val.Self_Assessment_Score);
                    });
                }
            });
        },        
    },
    watch:{
        rightData() {
            this.refashMaxScore();
        },
    }
}
</script>
<style lang="less" scoped>
.exa-head {
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info-content {
    display: flex;
    align-items: flex-start;
    padding: 20px 40px;

    .info-left {
        background: #fff;
        margin-right: 20px;
        width: 300px;
        padding: 10px 20px;


        .left-tit {
            font-size: 15px;
            font-weight: bold;
            color: #333;

            i {
                width: 5px;
                height: 16px;
                background: #328fff;
                display: inline-block;
                vertical-align: middle;
                border-radius: 50px;
                margin-right: 5px;
            }

        }

        .plan {
            display: flex;
            align-items: center;
            flex-flow: wrap;
            padding: 0 12px;
            margin: 20px 0;

            >div {
                width: 50%;
            }

            span {
                color: #409eff;
                font-size: 18px;
            }

            >div:nth-child(5) {
                span {
                    color: #fd931f;
                }
            }
        }

        .teacher {
            .teacherTit {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #f5f7fa;
                padding: 10px 10px;
            }

            .teacherList {
                .item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px 10px;
                }

                .active {
                    background: #e7f3ff;
                    border-left: 2px solid #0773fc;
                }
            }
        }
    }

    .info-right {
        background: #fff;
        width: 1000px;
        padding-bottom: 20px;
        height: 800px;
        overflow-y: scroll;

        .right-top {
            background: #dff0ff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 15px;
            padding: 10px 20px;

            .name {
                font-weight: normal;
            }

            .right-mark {
                span {
                    color: #328fff;
                }
            }
        }

        .right-tit {
            font-weight: bold;
            text-align: center;
            color: #1ea0fa;
            font-size: 18px;
            margin-top: 20px;

        }

        .time {
            text-align: center;
            margin-top: 10px;

            span {
                color: #b2bdc5;
            }
        }

        .prove {
            width: 80%;
            margin: 10px auto;
            color: #555555;
            padding-bottom: 20px;
            border-bottom: 1px dashed #c2c2c2;
        }

        .scoreBox {
            width: 80%;
            margin: 0 auto;

            .big {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
                color: #333;

                .score {
                    color: #328fff;
                    font-weight: inherit;
                }
            }

            .scoreBig {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #fafafa;
                padding: 20px 10px;
                margin-top: 10px;

                >div {
                    width: 50%;

                    &:nth-child(2) {
                        text-align: right;
                    }
                }

                label {
                    width: 40%;
                    color: #1ea0fa;
                    font-size: 20px;
                    text-align: left;
                }
            }

            .child {
                .small {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    margin-top: 10px;

                    .score {
                        color: #328fff;
                    }
                }

                .scoreSmall {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: #fafafa;
                    padding: 20px 10px;
                    margin-top: 10px;

                    >div {
                        width: 50%;

                        &:nth-child(2) {
                            text-align: right;
                        }
                    }

                    .el-input {
                        width: 40%;
                    }
                }
            }

        }
    }
}

.bottom {
    background: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 999998;
    padding: 10px 0;
}
</style>